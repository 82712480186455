import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import Chekin from 'assets/img/integrations/chekin.svg';
import useTranslation from 'hooks/useTranslation';
import React, { useState } from 'react';
import { useChekinIntegration } from '../../../hooks/Chekin/useChekinIntegration';
import { IntegrationsCard } from '../IntegrationsCard';

const CHEKIN_INFO_URL = 'https://chekin.com/en/identity-verification/';

export const ChekinIntegrationCard = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { isLoading, isEnabled, isConnected, enable, disable, sync } = useChekinIntegration();

  const { t } = useTranslation();

  const handleToggle = (checked: boolean) => {
    if (checked) {
      enable();
    } else {
      setIsDialogOpen(true);
    }
  };

  const handleDisableConfirmation = () => {
    setIsDialogOpen(false);
    disable();
  };

  const handleActionClick = () => {
    if (isConnected()) sync();
  };

  const getActionTitle = () => {
    if (isConnected()) return t('chekin_integration_card_action_title');

    return undefined;
  };

  return (
    <>
      <IntegrationsCard
        title='Chekin'
        connected={isConnected()}
        enabled={isEnabled()}
        loading={isLoading}
        actionTitle={getActionTitle()}
        onChange={handleToggle}
        onInfoClick={() => window.open(CHEKIN_INFO_URL)}
        onActionClick={handleActionClick}
      >
        <Chekin />
      </IntegrationsCard>
      <ConfirmDialog
        open={isDialogOpen}
        title={t('disable_chekin_integration_dialog_title')}
        onClose={() => setIsDialogOpen(false)}
        submitButtonText={t('disable_chekin_integration_dialog_submit')}
        onSubmit={handleDisableConfirmation}
      >
        <Typography variant='body1'>{t('disable_chekin_integration_dialog_text')}</Typography>
      </ConfirmDialog>
    </>
  );
};

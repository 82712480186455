import { Typography } from '@mui/material';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ISection } from 'redux/guestportal';
import { useGuidesSections } from 'redux/guestportal/hooks/useGuidesSections';
import { GuidesActionsType } from '../types';
import { GuidesForm } from './GuidesForm';
import GuidesTable from './GuidesTable';

const Guides = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [deletingSection, setDeletingSection] = useState<ISection>();
  const { section, save, remove } = useGuidesSections();

  const handleAction = (action: GuidesActionsType, section?: ISection) => {
    switch (action) {
      case 'add':
        navigate(`${pathname}/add`);
        break;

      case 'edit':
        navigate(`${pathname}/${section?.section_id}`);
        break;

      case 'delete':
        setDeletingSection(section);
        break;

      case 'feature':
        save({ ...section, featured: !section.featured });
        break;

      case 'publish':
        save({ ...section, published: !section.published });
        break;
    }
  };

  const handleDelete = () => {
    remove(deletingSection);
    setDeletingSection(undefined);
  };

  return (
    <>
      <ConfirmDialog
        open={deletingSection !== undefined}
        title='Delete Guide'
        submitButtonColor='error'
        onSubmit={handleDelete}
        onCancel={() => setDeletingSection(undefined)}
      >
        <Typography variant='body1'>
          Are you sure you want to delete the following Guide and associated Categories and Pages?
        </Typography>
        <Typography variant='body2' marginTop={2}>{`"${deletingSection?.section}"`}</Typography>
      </ConfirmDialog>

      <GuidesTable onAction={handleAction} />

      <GuidesForm section={section} onSave={save} />
    </>
  );
};

export default Guides;

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { trpc } from '@operto/trpc-client';
import { Variable } from '@operto/variables-shared';
import { trackEvent } from 'lib/analytics';
import { logger } from 'lib/logger';
import { getProperties } from 'property/state/propertyActions';
import { getAllPropertiesIdsWithNamesSelector } from 'property/state/propertySelectors';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import styled from 'styled-components';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';
import { currentUserSelector } from 'user/state/userSelectors';
import { newVariableTemplateWithoutId } from '../Custom';
import {
  MAX_VARIABLE_NAME_LENGTH,
  PositionAndTextObject,
  VARIABLE_BRACKET_LENGTH,
  VariableValueStatus,
  findAllPositionsAndTexts,
  getAllVariableNamesList,
  getAllVariables,
  getVariableStatusById,
} from '../helpers/VariableHelpers';

const VARIABLE_ON_TOP_CSS = {
  paddingBottom: '8px',
  borderBottom: '2px solid #BDBDBD',
};
const VARIABLE_ON_BOTTOM_CSS = {
  paddingTop: '8px',
  borderTop: '2px solid #BDBDBD',
};

const CURSOR_OFFSET = 3;
export interface NewVariableInputProps {
  variables: Variable[];
  onSave: (variableName: string) => void;
  onClose: () => void;
}

const NewVariableInlineInput = ({ variables, onSave, onClose }: NewVariableInputProps) => {
  const [newVariableName, setNewVariableName] = React.useState('');
  const [isVariableExist, setIsVariableExist] = React.useState(false);

  const handleNewVariableValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newVarName = event.target.value;
    if (newVarName.length > MAX_VARIABLE_NAME_LENGTH) {
      return;
    }

    const variable = variables.find((variable: Variable) => {
      return variable.name.toLowerCase() === newVarName.toLowerCase().trim();
    });
    setIsVariableExist(variable !== undefined);
    setNewVariableName(newVarName);
  };

  return (
    <Stack
      data-testid='new-variable-inline-input'
      direction='row'
      style={{ marginTop: '10px' }}
      sx={{ alignContent: 'top' }}
    >
      <TextField
        onChange={handleNewVariableValueChange}
        value={newVariableName}
        label='Variable Name'
        variant='outlined'
        size='small'
        autoFocus
        error={isVariableExist}
        helperText={
          isVariableExist
            ? 'Variable already exist.'
            : `Character Limit: ${newVariableName.length}/40`
        }
        style={{ marginLeft: '16px' }}
        sx={{ color: 'primary' }}
      />
      <Box my={2}>
        <IconButton
          data-testid='cancel-variable-creation-btn'
          size='small'
          style={{ marginLeft: '5px' }}
          onClick={onClose}
        >
          <ClearIcon fontSize='inherit' />
        </IconButton>
        <IconButton
          data-testid='confirm-variable-creation-btn'
          size='small'
          style={{ marginLeft: '5px' }}
          disabled={isVariableExist || newVariableName.length === 0}
          onClick={() => onSave(newVariableName.trim())}
        >
          <DoneIcon fontSize='inherit' />
        </IconButton>
      </Box>
    </Stack>
  );
};

export interface InsertVariablesMenuProps {
  anchorEl: HTMLElement;
  onVariableSelectedUpdate: (updatedText: string, position: number) => void;
  targetText: string;
  currentCursorPosition: number;
  isCustomVariablesEnabled?: boolean;
}

const InsertVariablesMenu = ({
  anchorEl,
  onVariableSelectedUpdate,
  targetText,
  currentCursorPosition,
  isCustomVariablesEnabled = true,
}: InsertVariablesMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [targetPosition, setTargetPosition] = React.useState<[number, number]>([0, 0]);
  const [isCreationModeOn, setIsCreationModeOn] = React.useState(false);

  const {
    data: customVariables,
    error: customError,
    isError: isCustomError,
    isLoading: isCustomLoading,
  } = trpc.variables.getVariables.useQuery({ type: 'custom' });
  const {
    data: systemVariables,
    isError: isSystemError,
    error: systemError,
    isLoading: isSystemLoading,
  } = trpc.variables.getVariables.useQuery({ type: 'system', enabled: true });

  const variables = getAllVariables(customVariables, systemVariables) ?? [];

  const menuItemRef = React.useRef<HTMLLIElement>(null);
  const currentUser = useSelector(currentUserSelector());

  const createVariableTRPC = trpc.variables.createVariable.useMutation();
  const variableUtils = trpc.useUtils();

  const dispatch = useAppDispatch();
  // this line all the way to line xxx should be removed after getting all properties from the backend
  useEffect(() => {
    const urlSearchParams = new URLSearchParams();
    dispatch(getProperties({ urlSearchParams }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCustomError) {
      logger.error(customError);
    }
  }, [isCustomError, customError]);

  useEffect(() => {
    if (isSystemError) {
      logger.error(systemError);
    }
  }, [isSystemError, systemError]);

  const propertiesIdWithNames = useSelector(getAllPropertiesIdsWithNamesSelector());
  const tailoredPropertiesForVariable = () => {
    const tailoredProperties = newVariableTemplateWithoutId().properties;

    propertiesIdWithNames.forEach(propertyIdAndName => {
      tailoredProperties[propertyIdAndName.id] = {
        id: propertyIdAndName.id,
        name: propertyIdAndName.name,
        value: '',
        enabled: true,
        group: '',
      };
    });
    return tailoredProperties;
  };
  // end of removable code for properties in variable. remove until here when backend is done.

  //   gets triggered when user changes the text in the editor
  useEffect(() => {
    const allPositions = findAllPositionsAndTexts(targetText);
    if (allPositions.length === 0) {
      setIsOpen(false);
      setTargetPosition([0, 0]);
      return;
    }
    allPositions.every((selectedPositionObj: PositionAndTextObject) => {
      setTargetPosition([selectedPositionObj.position[0], selectedPositionObj.position[1]]);
      if (
        variables.length > 0 &&
        !getAllVariableNamesList(variables).includes(selectedPositionObj.text)
      ) {
        setIsOpen(true);
        return false;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetText, isCustomLoading, isSystemError]);

  // gets triggered when the cursor position changes and user clicks on the variable
  useEffect(() => {
    const allPositions = findAllPositionsAndTexts(targetText);
    allPositions.every((selectedPositionObj: PositionAndTextObject) => {
      if (
        currentCursorPosition > selectedPositionObj.position[0] &&
        currentCursorPosition - CURSOR_OFFSET < selectedPositionObj.position[1]
      ) {
        setTargetPosition([selectedPositionObj.position[0], selectedPositionObj.position[1]]);
        setIsOpen(true);
        return false;
      }
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCursorPosition]);

  const handleMenuClose = () => {
    setIsCreationModeOn(false);
    setIsOpen(false);

    const allPositions = findAllPositionsAndTexts(targetText);
    allPositions.every((selectedPositionObj: PositionAndTextObject) => {
      setTargetPosition([selectedPositionObj.position[0], selectedPositionObj.position[1]]);
      if (!getAllVariableNamesList(variables).includes(selectedPositionObj.text)) {
        onVariableSelectedUpdate(
          targetText.replace(`*{{${selectedPositionObj.text}}}*`, ''),
          selectedPositionObj.position[1],
        );
        setIsOpen(false);
        return false;
      }
      return true;
    });
  };

  const handleVariableSelection = (variable: Variable) => {
    if (variable.enabled) {
      const updatedText =
        targetText.slice(0, targetPosition[0] + VARIABLE_BRACKET_LENGTH) +
        variable.name +
        targetText.slice(targetPosition[1], targetText.length);
      const endPosition = targetPosition[0] + 2 * VARIABLE_BRACKET_LENGTH + variable.name.length;
      onVariableSelectedUpdate(updatedText, endPosition);
      setIsOpen(false);
    }
  };

  const triggerVariableCreationMode = () => {
    setIsCreationModeOn(!isCreationModeOn);

    // Important for the search functionality to work after the creation mode is turned off
    if (isCreationModeOn) {
      menuItemRef.current.focus();
    }
  };

  const handleVariableCreation = (newVarName: string) => {
    setIsCreationModeOn(false);

    createVariableTRPC.mutate(
      newVariableTemplateWithoutId(currentUser?.name, {
        name: newVarName,
        properties: tailoredPropertiesForVariable(),
      }),
      {
        onSuccess: () => {
          variableUtils.variables.getVariables.refetch({ type: 'custom' });
        },
        onSettled: () => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable created successfully.',
              variant: SnackbarVariant.SUCCESS,
            }),
          );
        },
        onError: error => {
          dispatch(
            toggleSnackbar(SnackbarTypes.OPEN, {
              message: 'Variable creation failed.',
              variant: SnackbarVariant.ERROR,
            }),
          );
          logger.error(error);
        },
      },
    );

    trackEvent({
      screen: 'Variables',
      event: 'CREATED',
      environment: 'guest_portal',
    });
  };

  const handlePropagation = React.useCallback(
    (event: React.KeyboardEvent<HTMLLIElement> | React.KeyboardEvent<HTMLDivElement>) => {
      if (isCreationModeOn) event.stopPropagation();
    },
    [isCreationModeOn],
  );

  const VariablesMenuItemMapped = () => {
    if (!customVariables) {
      return false;
    }

    const iconWithToolTipProps = (variable: Variable) => {
      switch (getVariableStatusById(variable.id, customVariables)) {
        case VariableValueStatus.DISABLED:
          return { text: 'Deactivated', color: 'text.disabled' };
        case VariableValueStatus.MISSING_SOME_VALUE:
          return { text: 'Missing Value', color: 'warn' };
        case VariableValueStatus.MISSING_ALL_VALUE:
          return { text: 'Missing Value in all properties', color: 'info.main' };
        default:
          return false;
      }
    };

    if (!isCustomVariablesEnabled) {
      return systemVariables?.map((variable, index) => {
        return (
          <MenuItem
            onKeyDown={e => handlePropagation(e)}
            onKeyDownCapture={e => handlePropagation(e)}
            key={index}
            onClick={() => handleVariableSelection(variable)}
            sx={{ whiteSpace: 'normal' }}
          >
            <ListItemText>{variable.name}</ListItemText>
          </MenuItem>
        );
      });
    }

    return variables?.map((variable, index) => {
      const variableStatus = iconWithToolTipProps(variable);

      return (
        <MenuItem
          onKeyDown={e => handlePropagation(e)}
          onKeyDownCapture={e => handlePropagation(e)}
          key={index}
          onClick={() => handleVariableSelection(variable)}
          sx={{ whiteSpace: 'normal' }}
        >
          <ListItemText style={{ color: !variable.enabled ? 'text.disabled' : '' }}>
            {variable.name}
          </ListItemText>
          {variableStatus && (
            <Tooltip title={variableStatus.text} arrow>
              <CustomListIcon>
                <ErrorOutlineIcon sx={{ color: variableStatus.color }} />
              </CustomListIcon>
            </Tooltip>
          )}
        </MenuItem>
      );
    });
  };

  if (isSystemLoading || isCustomLoading) {
    return;
  }

  return (
    <Menu
      PaperProps={{ sx: { width: '380px', maxHeight: '200px', borderRadius: 2 } }}
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onKeyDownCapture={e => handlePropagation(e)}
      onKeyDown={e => handlePropagation(e)}
      data-testid='import-variables-menu'
    >
      {variables.length === 0 && (
        <Typography style={{ marginLeft: 20, marginBottom: 8 }}>No Results Found</Typography>
      )}
      {isCustomVariablesEnabled && (
        <MenuItem
          ref={menuItemRef}
          onKeyDown={e => handlePropagation(e)}
          onKeyDownCapture={e => handlePropagation(e)}
          style={variables.length > 0 ? VARIABLE_ON_TOP_CSS : VARIABLE_ON_BOTTOM_CSS}
          onClick={triggerVariableCreationMode}
          data-testid='create-variable-menu-item'
          sx={{ paddingLeft: '13px' }}
        >
          <CustomListIcon>
            <AddIcon sx={{ color: 'primary' }} color='primary' />
          </CustomListIcon>
          <ListItemText sx={{ color: 'primary' }}>Create Variable</ListItemText>
        </MenuItem>
      )}
      {isCreationModeOn && (
        <NewVariableInlineInput
          variables={variables}
          onClose={triggerVariableCreationMode}
          onSave={handleVariableCreation}
        />
      )}
      {variables.length > 0 && VariablesMenuItemMapped()}
    </Menu>
  );
};

const CustomListIcon = styled(ListItemIcon)`
  && {
    min-width: 32px;
  }
`;

export default InsertVariablesMenu;

import { Stack } from '@mui/material';
import { companySelector } from 'company/state/companySelectors';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import { useAppSelector } from 'redux/hooks';
import { ChekinIntegrationCard } from './Chekin/ChekinIntegrationCard';
import Cloudbeds from './Cloudbeds/Cloudbeds';
import GuestyIntegrationsCard from './Guesty/GuestyIntegrationsCard';
import HostawayIntegrationsCard from './Hostaway/HostawayIntegrationsCard';
import { IntegrationsSections } from './IntegrationsSections';
import MewsIntegrationsCard from './Mews/MewsIntegrationsCard';
import StreamlineIntegrationsCard from './Streamline/StreamlineIntegrationsCard';
import { StripeIntegrationCard } from './Stripe/StripeIntegrationCard';
import { StripeConnectIntegrationCard } from './StripeConnect/StripeConnectIntegrationCard';
import { YourKeyPlusIntegrationCard } from './YourKeyPlus/YourKeyPlusIntegrationCard';

const IntegrationsPage = () => {
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();
  const upsellsEnabled = isFeatureEnabled('upsells', company.id);
  const chekinEnabled = isFeatureEnabled('chekin', company.id);

  return (
    <Stack p={3}>
      <IntegrationsSections title='PMS'>
        <Cloudbeds />
        <GuestyIntegrationsCard />
        <MewsIntegrationsCard />
        <HostawayIntegrationsCard />
        <StreamlineIntegrationsCard />
      </IntegrationsSections>
      {upsellsEnabled && (
        <IntegrationsSections title='Payment'>
          <StripeConnectIntegrationCard />
        </IntegrationsSections>
      )}
      <IntegrationsSections title='Access'>
        <YourKeyPlusIntegrationCard />
      </IntegrationsSections>
      <IntegrationsSections title='Verification'>
        <StripeIntegrationCard />
        {chekinEnabled && <ChekinIntegrationCard />}
      </IntegrationsSections>
    </Stack>
  );
};

export default IntegrationsPage;

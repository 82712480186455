import { Typography } from '@mui/material';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { WorkflowDataType } from '@operto/communications-shared';
import ConfirmDialog from 'Common/Dialog/ConfirmDialog';
import { Loading } from 'Common/Loading';
import { ChannelType, useAutomate } from 'hooks/useAutomate';
import useAutomateFilters from 'hooks/useAutomateFilters';
import useCommunications from 'hooks/useCommunications';
import useSnackbar from 'hooks/useSnackbar';
import useTranslation from 'hooks/useTranslation';
import { logger } from 'lib/logger';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  PaginatedTable,
  getSortModelByUrl,
  sortTableByUrl,
} from 'ui-library/Components/table/PaginatedTable';
import AutomateTitlebar from './AutomateTitlebar';
import CreateTemplate from './CreateTemplate';
import CreateWorkflow from './CreateWorkflow';
import useAutomateColumns from './useAutomateColumns';

const PAGE_NUM = 0;
const PAGE_SIZE = 100;

const AutomateTable = () => {
  const { t } = useTranslation();
  const { snackbar } = useSnackbar();
  const automateFilters = useAutomateFilters();
  const columns = useAutomateColumns({
    onToggleWorkflowEnabled: (workflowId: string, enabled: boolean) => {
      setConfirmDialogState({ workflowId, enabled });
      setIsDialogOpen(true);
    },
  });

  const firstLoad = useRef(true);
  const [isSavingDialogState, setIsSavingDialogState] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [confirmDialogState, setConfirmDialogState] = useState<{
    workflowId: string;
    enabled: boolean;
  }>();

  const channelsSelected = automateFilters.searchParams.get('channel')?.split(',') as ChannelType[];

  const activeSelected = automateFilters.searchParams
    .get('active')
    ?.split(',')
    ?.map(value => value === 'true') as boolean[];

  const hasFilters = channelsSelected || activeSelected;

  const {
    workflows,
    isLoading: isAutomateLoading,
    isFetching: isAutomateFetching,
    updateWorkflow,
    automateRefetch,
  } = useAutomate({
    channels: channelsSelected,
    running: activeSelected,
  });

  const [filteredWorkflows, setFilteredWorkflows] = useState<WorkflowDataType>();

  const handleWorkflowActivate = async () => {
    setIsSavingDialogState(true);

    try {
      const selectedWorkflow = workflows?.find(
        workflow => workflow.id === confirmDialogState?.workflowId,
      );

      const newWorkflow = {
        ...selectedWorkflow,
        enabled: confirmDialogState?.enabled,
      };

      await updateWorkflow(newWorkflow);
      await automateRefetch();

      snackbar(t(confirmDialogState?.enabled ? 'flow_activated' : 'flow_deactivated'));
    } catch (error) {
      logger.debug(error);
      snackbar(t('error_saving'));
    }

    setIsSavingDialogState(false);
    setIsDialogOpen(false);
  };

  const handleSearch = useCallback(
    (pageNum: number, numPerPage: number, searchString?: string) => {
      setSearchString(searchString || '');

      if (!searchString?.length) {
        return setFilteredWorkflows(workflows);
      }

      const lowerCaseSearchString = searchString.toLowerCase();
      const filteredData = workflows?.filter(row => {
        return row.name.toLowerCase().includes(lowerCaseSearchString);
      });

      setFilteredWorkflows(filteredData);
    },
    [workflows],
  );
  const {
    data: templates,
    isLoading: isTemplatesLoading,
    isFetching: isTemplatesFetching,
  } = useCommunications();

  const isLoading = isAutomateLoading || isTemplatesLoading;
  const isFetching = isAutomateFetching || isTemplatesFetching;

  useEffect(() => {
    if (workflows && filteredWorkflows === undefined) {
      setFilteredWorkflows(workflows);
    }
  }, [filteredWorkflows, workflows]);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    }

    handleSearch(PAGE_NUM, PAGE_SIZE, searchString);
  }, [handleSearch, searchString]);

  const renderContent = () => {
    if (firstLoad.current && isLoading) {
      return <Loading />;
    }

    if (!hasFilters && !isLoading && !templates?.length && !workflows?.length) {
      return <CreateTemplate />;
    }

    if (!hasFilters && !isLoading && !workflows?.length) {
      return <CreateWorkflow />;
    }

    return (
      <PaginatedTable
        enableToolbar
        rows={filteredWorkflows ?? []}
        columns={columns}
        disableAdd={templates?.length === 0}
        initialState={tableInitialState}
        onFetch={handleSearch}
        onSortModelChange={model =>
          automateFilters.onFilterChange(sortTableByUrl(model, automateFilters.searchParams))
        }
        sortModel={getSortModelByUrl(automateFilters.searchParams)}
        filtersToolbarProps={automateFilters}
        loading={!automateFilters.isSynched || (isFetching && (!templates || !workflows))}
      />
    );
  };

  return (
    <>
      <AutomateTitlebar displayAddButton={isLoading || workflows?.length > 0} />

      {renderContent()}

      <ConfirmDialog
        open={isDialogOpen}
        onSubmit={handleWorkflowActivate}
        onCancel={() => setIsDialogOpen(false)}
        loading={isSavingDialogState}
        title={
          <Typography variant='h3-700' sx={{ padding: '16px 24px' }}>
            {t(confirmDialogState?.enabled ? 'activate_flow' : 'deactivate_flow')}?
          </Typography>
        }
        submitButtonText={t(confirmDialogState?.enabled ? 'activate' : 'deactivate')}
        rootStyles={{ '& div[role=dialog]': { maxWidth: '444px', borderRadius: '10px' } }}
        contentStyles={{ padding: '8px 24px' }}
        actionsStyles={{
          padding: '8px 16px 16px 8px',
          '& button': { textTransform: 'capitalize', fontWeight: '700' },
        }}
      >
        <Typography variant='body-lg-400'>
          {t(confirmDialogState?.enabled ? 'activate_flow_text' : 'deactivate_flow_text')}
        </Typography>
      </ConfirmDialog>
    </>
  );
};

export default AutomateTable;

const tableInitialState: GridInitialStatePro = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'asc' }],
  },
};

import { useVerification } from 'hooks/useVerification';

/**
 * Custom hook to manage the Chekin integration state.
 *
 * This hook provides functions to enable, disable, and sync the Chekin integration,
 * as well as to check the current status of the integration.
 *
 * @returns {Object} An object containing the following properties and functions:
 * - `isLoading` (boolean): Indicates if the integration state is currently loading.
 * - `isSuccess` (boolean): Indicates if the integration state has succeeded.
 * - `isEnabled` (function): Checks if the Chekin integration is enabled.
 * - `isConnected` (function): Checks if the Chekin integration is connected.
 * - `enable` (function): Enables the Chekin integration.
 * - `disable` (function): Disables the Chekin integration.
 * - `sync` (function): Syncs the Chekin data.
 */
export const useChekinIntegration = () => {
  const { getCompanyVerificationProvider, upsertCompanyProvider } = useVerification();
  const { isLoading, isFetching, isSuccess, isError, error, provider } =
    getCompanyVerificationProvider();

  /**
   * Checks if the integration is connected.
   *
   * @returns {boolean} True if the integration is connected, otherwise false.
   */
  const isConnected = () => {
    return isSuccess || error?.data?.code === 'NOT_FOUND';
  };

  /**
   * Checks if the Chekin integration is enabled.
   *
   * @returns {boolean} the provider is enabled, and the provider is 'chekin'; otherwise, false.
   */
  const isEnabled = () => {
    return !isError && provider?.enabled && provider?.provider === 'chekin';
  };

  /**
   * Enables the Chekin integration
   *
   * @async
   * @function
   * @returns {Promise<void>} A promise that resolves when the integration state is updated.
   */
  const enable = async () => {
    await upsertCompanyProvider(true);
  };

  /**
   * Disables the Chekin integration.
   *
   * @async
   * @function disable
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const disable = async () => {
    await upsertCompanyProvider(false);
  };

  const sync = () => {
    //TODO
  };

  return {
    isLoading: isLoading || isFetching,
    isSuccess,
    isEnabled,
    isConnected,
    enable,
    disable,
    sync,
  };
};

import { trpc } from '@operto/trpc-client';
import { companySelector } from 'company/state/companySelectors';
import { logger } from 'lib/logger';
import { toggleSnackbar } from 'redux/actions/ui';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { SnackbarTypes, SnackbarVariant } from 'types/ui';

/**
 * Custom hook to manage company verification providers.
 *
 * @returns {Object} An object containing functions to fetch, upsert, and refetch the company verification provider.
 *
 * @function getCompanyVerificationProvider - Fetches the verification provider for a company.
 * @function upsertCompanyProvider - Upserts the verification provider for a company.
 */
export function useVerification() {
  const company = useAppSelector(companySelector());
  const dispatch = useAppDispatch();
  const trpcUtils = trpc.useUtils();

  /**
   * Fetches the verification provider for a company.
   *
   * @returns An object containing the verification provider data, loading status, success status, error status, and any error encountered.
   *
   * @property {boolean} isLoading - Indicates if the query is currently loading.
   * @property {boolean} isSuccess - Indicates if the query was successful.
   * @property {boolean} isError - Indicates if there was an error with the query.
   * @property {Error | null} error - The error encountered during the query, if any.
   * @property {CompanyProvider} data - The verification provider data returned by the query.
   */
  const getCompanyVerificationProvider = () => {
    const { isLoading, isFetching, isSuccess, isError, error, data } =
      trpc.verifications.getCompanyProvider.useQuery({
        id: company.code,
      });

    if (isError) {
      logger.error('Error fetching company provider', error);

      if (error?.data?.code != 'NOT_FOUND') {
        dispatch(
          toggleSnackbar(SnackbarTypes.OPEN, {
            message: error.message,
            variant: SnackbarVariant.ERROR,
          }),
        );
      }
    }

    return {
      provider: data,
      error,
      isSuccess,
      isLoading,
      isFetching,
      isError,
    };
  };

  const upsertCompanyProviderMutation = trpc.verifications.upsertCompanyProvider.useMutation();
  /**
   * Upserts the company provider with the given enabled status.
   *
   * @param {boolean} enabled - The status to enable or disable the company provider.
   * @returns {Promise<void>} A promise that resolves when the upsert operation is complete.
   */
  const upsertCompanyProvider = async (enabled: boolean) => {
    try {
      await upsertCompanyProviderMutation.mutateAsync({
        id: company.code,
        enabled: enabled,
        email: company.email,
        provider: 'chekin',
      });
      trpcUtils.verifications.getCompanyProvider.refetch({ id: company.code });
    } catch (error) {
      logger.error('Error upserting company provider', error);
      dispatch(
        toggleSnackbar(SnackbarTypes.OPEN, {
          message: error.message,
          variant: SnackbarVariant.ERROR,
        }),
      );
    }
  };

  return {
    getCompanyVerificationProvider,
    upsertCompanyProvider,
  };
}

import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined';
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { postTasksChat } from 'api/messagingAPI';
import { getReservationById } from 'api/reservationAPI';
import useSnackbar from 'hooks/useSnackbar';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TeamsCreatedTasksDialog } from './TeamsCreatedTasksDialog';
import { TeamsServicesDialog } from './TeamsServicesDialog';
import { TeamsTaskDialog } from './TeamsTasksDialog';

type TeamsTasksMenuItemProps = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
};

const TeamsTasksMenuItem = ({ title, icon, onClick }: TeamsTasksMenuItemProps) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{title}</ListItemText>
    </MenuItem>
  );
};

export const TeamsTaskMenu = () => {
  const { pathname } = useLocation();
  const { snackbar } = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState<'services' | 'tasks' | 'createdTasks'>();
  const [tasksMenuAchor, setTasksMenuAnchor] = useState<HTMLButtonElement>();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (value: string) => {
    setSubmitting(true);
    const reservationId = pathname.split('-').pop();
    try {
      if (!reservationId) {
        throw new Error('Reservation ID not found');
      }
      const reservation = await getReservationById(reservationId);
      const reservationExternalId = reservation?.data?.data?.external_id;
      if (!reservationExternalId) {
        throw new Error('Reservation external ID not found');
      }

      await postTasksChat({
        reservationId: reservationExternalId,
        name: dialogOpen === 'tasks' ? value : undefined,
        serviceId: dialogOpen === 'services' ? value : undefined,
      });
      snackbar('Task created successfully');
      setDialogOpen(undefined);
    } catch (error) {
      snackbar('Failed to create task');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <IconButton onClick={e => setTasksMenuAnchor(e.currentTarget)} size='large'>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={tasksMenuAchor}
        open={Boolean(tasksMenuAchor)}
        onClose={() => setTasksMenuAnchor(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <TeamsTasksMenuItem
          title='Tasks'
          icon={<RoomServiceOutlinedIcon />}
          onClick={() => {
            setDialogOpen('services');
            setTasksMenuAnchor(null);
          }}
        />
        <TeamsTasksMenuItem
          title='Custom Tasks'
          icon={<AddIcon />}
          onClick={() => {
            setDialogOpen('tasks');
            setTasksMenuAnchor(null);
          }}
        />
        <Divider />
        <TeamsTasksMenuItem
          title='Go to Teams Scheduling Calendar'
          icon={<CalendarMonthIcon />}
          onClick={() => {
            window.open('https://vrscheduler.operto-qa.com/mastercalendar-tasks2', '__blank');
            setTasksMenuAnchor(null);
          }}
        />
        <TeamsTasksMenuItem
          title='View Created Tasks'
          icon={<AssignmentIcon />}
          onClick={() => {
            setDialogOpen('createdTasks');
            setTasksMenuAnchor(null);
          }}
        />
      </Menu>

      <TeamsTaskDialog
        loading={isSubmitting}
        open={dialogOpen === 'tasks'}
        onCancel={() => setDialogOpen(undefined)}
        onSubmit={handleSubmit}
      />
      <TeamsServicesDialog
        loading={isSubmitting}
        open={dialogOpen === 'services'}
        onCancel={() => setDialogOpen(undefined)}
        onSubmit={handleSubmit}
      />
      <TeamsCreatedTasksDialog
        open={dialogOpen === 'createdTasks'}
        onCancel={() => setDialogOpen(undefined)}
      />
    </>
  );
};

import CheckIcon from '@mui/icons-material/Check';
import { Button, Grid, Switch } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { AddCard } from '@operto/ui-library';
import { Role } from 'Common/Tables/helpers';
import { sendMemberPasswordSetupEmail } from 'api/memberAPI';
import { AxiosResponse } from 'axios';
import useTranslation from 'hooks/useTranslation';
import { IMember, MemberSiteAccess } from 'member/memberType';
import React, { useState } from 'react';
import { isAdmin } from 'redux/actions/ui';
import { useAppSelector } from 'redux/hooks';
import { FormHeader } from 'ui-library/Styled/sidebar';
import { userSelector } from 'user/state/userSelectors';
import { UserRole, UserState } from 'user/userTypes';

export const roleCanGrantSelectedPermission = (
  loggedInMember: UserState,
  data: IMember,
  selectedPermisson: UserRole,
) => {
  const currentUserId = loggedInMember.user.id;
  const currentUserRole = loggedInMember.role;
  const selectedUserId = data.id;
  const selectedUserRole = data.role;

  const isSameUser = currentUserId === selectedUserId;
  const isAccountOwner = currentUserRole === Role.AccountOwner;
  const isGlobalManager = currentUserRole === Role.GlobalManager;
  const isRegionalManager = currentUserRole === Role.RegionalManager;
  const isBasicUser = currentUserRole === Role.BasicUser;

  const selectedIsAccountOwner = selectedUserRole === Role.AccountOwner;
  const selectedIsGlobalManager = selectedUserRole === Role.GlobalManager;
  const selectedIsBasicUser = selectedUserRole === Role.BasicUser;

  const selectedPermissionIsAccountOwner = selectedPermisson === Role.AccountOwner;
  const selectedPermissionIsGlobalManager = selectedPermisson === Role.GlobalManager;
  const selectedPermissionIsBasicUser = selectedPermisson === Role.BasicUser;

  if (isAccountOwner) return true;

  if (isSameUser) {
    if (isGlobalManager) {
      return !(selectedIsAccountOwner || selectedPermissionIsAccountOwner);
    }

    if (isRegionalManager) {
      return !(
        selectedIsAccountOwner ||
        selectedIsGlobalManager ||
        selectedPermissionIsAccountOwner ||
        selectedPermissionIsGlobalManager
      );
    }

    if (isBasicUser) {
      return selectedIsBasicUser && selectedPermissionIsBasicUser;
    }
  } else {
    if (isGlobalManager) {
      return !(
        selectedIsAccountOwner ||
        selectedIsGlobalManager ||
        selectedPermissionIsAccountOwner ||
        selectedPermissionIsGlobalManager
      );
    }

    if (isRegionalManager) {
      return selectedIsBasicUser && selectedPermissionIsBasicUser;
    }

    if (isBasicUser) {
      return selectedIsBasicUser && selectedPermissionIsBasicUser;
    }
  }

  return false;
};

interface PermissionsComponentProps {
  data: IMember;
  onChange: (field: string, value: unknown) => void;
}

const PermissionsComponent = ({ data, onChange }: PermissionsComponentProps) => {
  const [sendButton, setSendButton] = useState<boolean>(true);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const loggedInMember: UserState = useAppSelector(userSelector());
  const { t } = useTranslation();

  const hasDashboardAccess = data.site_access_id?.includes(MemberSiteAccess.DASHBOARD) ?? false;
  const hasStaffAccess = data.site_access_id?.includes(MemberSiteAccess.STAFF) ?? false;

  const valueChanger = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const field = 'role';
    onChange(field, value);
  };

  const dashboardAccessHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const siteAccessIds = data.site_access_id ?? [];
    const updatedSiteAccessIds = e.target.checked
      ? [...siteAccessIds, MemberSiteAccess.DASHBOARD]
      : siteAccessIds.filter(id => id !== MemberSiteAccess.DASHBOARD);

    onChange(e.target.name, e.target.checked);
    onChange('site_access_id', updatedSiteAccessIds);
  };

  const staffAccessHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const staffAccessIds = data.site_access_id ?? [];
    const updatedStaffAccessIds = e.target.checked
      ? [...staffAccessIds, MemberSiteAccess.STAFF]
      : staffAccessIds.filter(id => id !== MemberSiteAccess.STAFF);

    onChange('site_access_id', updatedStaffAccessIds);
    onChange(e.target.name, e.target.checked);
  };

  const handleSendEmail = () => {
    setSendButton(false);

    sendMemberPasswordSetupEmail(data.id, data.email).then((response: AxiosResponse) => {
      if (response.status === 200) {
        setSendSuccess(true);
        setSendButton(true);
      } else {
        setSendButton(true);
      }
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AddCard title='User Permissions'>
          <Grid>
            <FormHeader>{t('select_user_access')}</FormHeader>

            <RadioGroup>
              <FormControlLabel
                control={
                  <Radio
                    value='account-owner'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'account-owner'}
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.AccountOwner)
                    }
                  />
                }
                label={t('account_owner')}
              />
              <FormControlLabel
                control={
                  <Radio
                    value='global-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'global-manager'}
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.GlobalManager)
                    }
                  />
                }
                label={t('global_manager')}
              />
              <FormControlLabel
                control={
                  <Radio
                    value='regional-manager'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'regional-manager'}
                    disabled={
                      !roleCanGrantSelectedPermission(loggedInMember, data, Role.RegionalManager)
                    }
                  />
                }
                label={t('regional_manager')}
              />
              <FormControlLabel
                control={
                  <Radio
                    value='basic-user'
                    onChange={valueChanger}
                    checked={'role' in data && data.role === 'basic-user'}
                    disabled={!roleCanGrantSelectedPermission(loggedInMember, data, Role.BasicUser)}
                  />
                }
                label={t('basic_access')}
              />
            </RadioGroup>
          </Grid>
        </AddCard>

        <AddCard title={t('access')}>
          <Grid container direction='column'>
            <FormControlLabel
              control={
                <Switch
                  name='is_dashboard_access'
                  color='primary'
                  onChange={dashboardAccessHandler}
                  checked={hasDashboardAccess}
                />
              }
              label={t('access_to_connect_dashboard')}
            />

            {isAdmin() && (
              <FormControlLabel
                control={
                  <Switch
                    name='is_staff_access'
                    color='primary'
                    onChange={staffAccessHandler}
                    checked={hasStaffAccess}
                  />
                }
                label={t('access_to_staff_app')}
              />
            )}

            <FormHeader>{t('account_password')}</FormHeader>

            <div>
              <FormControlLabel
                control={<Checkbox checked={data.is_password_set} name='is_password_set' />}
                label={t('is_password_set')}
              />
              {!data.is_password_set && (hasDashboardAccess || hasStaffAccess) && (
                <>
                  <FormHeader variant='body2'>{t('is_password_set_description')}</FormHeader>

                  <Button
                    variant='contained'
                    color={sendSuccess ? 'secondary' : 'primary'}
                    onClick={handleSendEmail}
                    disabled={!sendButton || sendSuccess}
                  >
                    {t('send_password_setup_email')}
                  </Button>
                  {sendSuccess ? <CheckIcon /> : !sendButton && <CircularProgress size={24} />}
                </>
              )}
            </div>
          </Grid>
        </AddCard>
      </Grid>
    </Grid>
  );
};

export default PermissionsComponent;

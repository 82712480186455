import AssocEntryIcon from '@mui/icons-material/CenterFocusWeak';
import HumidityIcon from '@mui/icons-material/HotTub';
import OccupancyIcon from '@mui/icons-material/PeopleAltOutlined';
import AdditionLockIcon from '@mui/icons-material/PhonelinkLock';
import EnergyIcon from '@mui/icons-material/SettingsPower';
import TemperatureIcon from '@mui/icons-material/Thermostat';
import NoiseIcon from '@mui/icons-material/VolumeUp';
import { getDevicesByPropertyId } from 'device/state/deviceActions';
import useTranslation from 'hooks/useTranslation';
import { Property } from 'property/propertyType';
import { getFavoritePropertyIds, getProperty } from 'property/state/propertyActions';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as uiActions from 'redux/actions/ui';
import { useAppDispatch } from 'redux/hooks';
import { ApplicationState } from 'redux/reducers';
import { getPropertyReservationsCurrent } from 'reservation/state/reservationActions';
import { getDeviceThresholdsByPropertyId } from 'threshold/state/thresholdAction';
import TabBar, { TabBarProps, TabItems } from 'ui-library/Components/tabBar/TabBar';

export const UNIT_DASHBOARD_TAB = 0;
export const UNIT_GUEST_TAB = 1;

export const UNIT_ACCESS_TAB = 2;
export const UNIT_ACCESS_TAB_UNIT_CODE = 2.1;
export const UNIT_ACCESS_TAB_ASSOCIATE_ENTRIES = 2.2;
export const UNIT_ACCESS_TAB_ADDITIONAL_LOCKS = 2.3;
export const UNIT_ACCESS_BUILDING_INTERCOM = 2.4;
export const UNIT_ACCESS_COMMON_DOORS = 2.5;
export const UNIT_ACCESS_GUEST_CODES = 2.6;
export const UNIT_ACCESS_BACKUP_CODES = 2.7;
export const UNIT_ACCESS_TAB_MEMBER_CODES = 2.8;

export const UNIT_DEVICE_TAB = 3;
export const UNIT_DEVICE_TAB_NOISE = 3.1;
export const UNIT_DEVICE_TAB_TEMPERATURE = 3.2;
export const UNIT_DEVICE_TAB_OCCUPANCY = 3.3;
export const UNIT_DEVICE_TAB_HUMIDITY = 3.4;
export const UNIT_DEVICE_TAB_ENERGY = 3.5;
export const UNIT_DEVICE_TAB_CONNECTED_DEVICES = 3.6;

export const UNIT_GUEST_PORTAL_TAB = 5;
export const UNIT_GUEST_PORTAL_UNIT_DETAILS_TAB = 5.1;
export const UNIT_GUEST_PORTAL_CUSTOM_HOST_TAB = 5.2;
export const UNIT_GUEST_PORTAL_CUSTOM_ADDRESS_TAB = 5.3;
export const UNIT_GUEST_PORTAL_CUSTOM_TEXT_TAB = 5.4;
export const UNIT_GUEST_PORTAL_CUSTOM_HOUSE_MANUAL_TAB = 5.5;
export const UNIT_GUEST_PORTAL_CUSTOM_GUIDES_TAB = 5.6;
export const UNIT_GUEST_PORTAL_CUSTOM_FEATURED_TAB = 5.7;

export const UNIT_SETTINGS = 6;

const UnitPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { propertyId: pid } = useParams();
  const propertyId = parseInt(pid, 10);
  const navigate = useNavigate();
  const location = useLocation();
  const [filterIndex, setFilterIndex] = useState(0);
  const propertiesById = useSelector((state: ApplicationState) => state.properties.byId);
  const currentProperty: Property = propertiesById[propertyId];
  const isGuestPortalInitialized = currentProperty?.guest_portal_initialized;
  const isGlobalGuestPortalEnabled = useSelector(
    (state: ApplicationState) => state.user?.features?.mp_guest_portal_onboarding_enabled,
  );
  const isGuestPortalEnabled = useSelector(
    (state: ApplicationState) => state.members.byId[state.user.user.id]?.role !== 'basic-user',
  );

  useEffect(() => {
    dispatch(getDevicesByPropertyId(propertyId, null, null));
    dispatch(uiActions.setPropertyId(propertyId));
    dispatch(getProperty(propertyId));
    dispatch(getDeviceThresholdsByPropertyId(pid));
    dispatch(getPropertyReservationsCurrent(propertyId));
    dispatch(getFavoritePropertyIds());
  }, [dispatch, propertyId, pid]);

  useEffect(() => {
    const selectedTab = location.pathname.split('/')[3];
    if (selectedTab !== undefined) {
      switch (selectedTab) {
        case 'guests':
          if (filterIndex !== UNIT_GUEST_TAB) setFilterIndex(UNIT_GUEST_TAB);
          break;
        case 'access':
          if (filterIndex !== UNIT_ACCESS_TAB) setFilterIndex(UNIT_ACCESS_TAB);
          break;
        case 'devices':
          if (filterIndex !== UNIT_DEVICE_TAB) setFilterIndex(UNIT_DEVICE_TAB);
          break;
        case 'guest-portal':
          if (filterIndex !== UNIT_GUEST_PORTAL_TAB) setFilterIndex(UNIT_GUEST_PORTAL_TAB);
          break;
        default:
          if (filterIndex !== UNIT_DASHBOARD_TAB) setFilterIndex(UNIT_DASHBOARD_TAB);
      }
    }
  }, [filterIndex, location.pathname]);

  const selectFilter = (index: number) => {
    switch (index) {
      case UNIT_DASHBOARD_TAB:
        navigate(`/units/${propertyId}`);
        setFilterIndex(UNIT_DASHBOARD_TAB);
        break;
      case UNIT_ACCESS_TAB:
        navigate(`/units/${propertyId}/access`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_TAB_UNIT_CODE:
        navigate(`/units/${propertyId}/access/unit-code`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_TAB_ASSOCIATE_ENTRIES:
        navigate(`/units/${propertyId}/access/associate-entries`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_BUILDING_INTERCOM:
        navigate(`/units/${propertyId}/access/unit-code/building-intercom`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_COMMON_DOORS:
        navigate(`/units/${propertyId}/access/unit-code/common-doors`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_GUEST_CODES:
        navigate(`/units/${propertyId}/access/unit-code/guests`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_BACKUP_CODES:
        navigate(`/units/${propertyId}/access/unit-code/backups`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_TAB_MEMBER_CODES:
        navigate(`/units/${propertyId}/access/unit-code/members`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_ACCESS_TAB_ADDITIONAL_LOCKS:
        navigate(`/units/${propertyId}/access/unit-code/connected-locks`);
        setFilterIndex(UNIT_ACCESS_TAB);
        break;
      case UNIT_DEVICE_TAB:
        navigate(`/units/${propertyId}/devices`);
        setFilterIndex(UNIT_DEVICE_TAB);
        break;
      case UNIT_DEVICE_TAB_NOISE:
        navigate(`/units/${propertyId}/devices/noise`);
        setFilterIndex(UNIT_DEVICE_TAB);
        break;
      case UNIT_DEVICE_TAB_TEMPERATURE:
        navigate(`/units/${propertyId}/devices/temperature`);
        setFilterIndex(UNIT_DEVICE_TAB);
        break;
      case UNIT_DEVICE_TAB_OCCUPANCY:
        navigate(`/units/${propertyId}/devices/occupancy`);
        setFilterIndex(UNIT_DEVICE_TAB);
        break;
      case UNIT_DEVICE_TAB_HUMIDITY:
        navigate(`/units/${propertyId}/devices/humidity`);
        setFilterIndex(UNIT_DEVICE_TAB);
        break;
      case UNIT_DEVICE_TAB_ENERGY:
        navigate(`/units/${propertyId}/devices/energy`);
        setFilterIndex(UNIT_DEVICE_TAB);
        break;
      case UNIT_DEVICE_TAB_CONNECTED_DEVICES:
        navigate(`/units/${propertyId}/devices/connected`);
        setFilterIndex(UNIT_DEVICE_TAB);
        break;
      case UNIT_GUEST_TAB:
        navigate(`/units/${propertyId}/guests`);
        setFilterIndex(UNIT_GUEST_TAB);
        break;
      case UNIT_GUEST_PORTAL_UNIT_DETAILS_TAB:
        navigate(`/units/${propertyId}/guest-portal/unit-details`);
        setFilterIndex(UNIT_GUEST_PORTAL_TAB);
        break;
      case UNIT_GUEST_PORTAL_CUSTOM_HOST_TAB:
        navigate(`/units/${propertyId}/guest-portal/custom-host`);
        setFilterIndex(UNIT_GUEST_PORTAL_TAB);
        break;
      case UNIT_GUEST_PORTAL_CUSTOM_ADDRESS_TAB:
        navigate(`/units/${propertyId}/guest-portal/custom-address`);
        setFilterIndex(UNIT_GUEST_PORTAL_TAB);
        break;
      case UNIT_GUEST_PORTAL_CUSTOM_TEXT_TAB:
        navigate(`/units/${propertyId}/guest-portal/custom-text`);
        setFilterIndex(UNIT_GUEST_PORTAL_TAB);
        break;
      case UNIT_GUEST_PORTAL_CUSTOM_HOUSE_MANUAL_TAB:
        navigate(`/units/${propertyId}/guest-portal/custom-house-manual`);
        setFilterIndex(UNIT_GUEST_PORTAL_TAB);
        break;
      case UNIT_GUEST_PORTAL_CUSTOM_GUIDES_TAB:
        navigate(`/units/${propertyId}/guest-portal/guides`);
        setFilterIndex(UNIT_GUEST_PORTAL_TAB);
        break;
      default:
        navigate(`/units/${propertyId}`);
        setFilterIndex(UNIT_DASHBOARD_TAB);
    }
  };

  const setTabIndex = (tabValue: number) => {
    // truncate decimal points to detect which tab you are
    const integerTabValue = Math.floor(tabValue);
    selectFilter(integerTabValue);
  };

  const tabItems = [
    { label: t('overview'), value: UNIT_DASHBOARD_TAB, isDropDown: false },
    { label: t('guests'), value: UNIT_GUEST_TAB, isDropDown: false },
    {
      label: t('access'),
      isDropDown: true,
      childItems: [
        {
          label: t('guests_codes'),
          value: UNIT_ACCESS_GUEST_CODES,
          icon: <AssocEntryIcon />,
        },
        {
          label: t('backup_codes'),
          value: UNIT_ACCESS_BACKUP_CODES,
          icon: <AssocEntryIcon />,
        },
        {
          label: t('member_codes'),
          value: UNIT_ACCESS_TAB_MEMBER_CODES,
          icon: <AssocEntryIcon />,
        },
        {
          label: t('building_intercom'),
          value: UNIT_ACCESS_BUILDING_INTERCOM,
          icon: <AssocEntryIcon />,
        },
        {
          label: t('common_doors'),
          value: UNIT_ACCESS_COMMON_DOORS,
          icon: <AssocEntryIcon />,
        },
        {
          label: t('connected_locks'),
          value: UNIT_ACCESS_TAB_ADDITIONAL_LOCKS,
          icon: <AdditionLockIcon />,
        },
      ],
    },
    {
      label: t('monitoring'),
      isDropDown: true,
      childItems: [
        {
          label: t('noise_monitoring'),
          value: UNIT_DEVICE_TAB_NOISE,
          icon: <NoiseIcon />,
        },
        {
          label: t('temperature'),
          value: UNIT_DEVICE_TAB_TEMPERATURE,
          icon: <TemperatureIcon />,
        },
        {
          label: t('occupancy'),
          value: UNIT_DEVICE_TAB_OCCUPANCY,
          icon: <OccupancyIcon />,
        },
        {
          label: t('humidity'),
          value: UNIT_DEVICE_TAB_HUMIDITY,
          icon: <HumidityIcon />,
        },
        {
          label: t('energy_manager'),
          value: UNIT_DEVICE_TAB_ENERGY,
          icon: <EnergyIcon />,
        },
        {
          label: t('connected_devices'),
          value: UNIT_DEVICE_TAB_CONNECTED_DEVICES,
          icon: <AdditionLockIcon />,
        },
      ],
    },
    {
      label: t('guest_portal'),
      isDropDown: true,
      childItems: [
        {
          label: t('unit_details'),
          value: UNIT_GUEST_PORTAL_UNIT_DETAILS_TAB,
        },
        {
          label: t('custom_host'),
          value: UNIT_GUEST_PORTAL_CUSTOM_HOST_TAB,
        },
        {
          label: t('custom_address'),
          value: UNIT_GUEST_PORTAL_CUSTOM_ADDRESS_TAB,
        },
        {
          label: t('custom_text'),
          value: UNIT_GUEST_PORTAL_CUSTOM_TEXT_TAB,
        },
        {
          label: t('custom_guides'),
          value: UNIT_GUEST_PORTAL_CUSTOM_GUIDES_TAB,
        },
      ],
    },
  ];

  return (
    <>
      <TabBar
        tabItems={
          isGlobalGuestPortalEnabled
            ? isGuestPortalEnabled && isGuestPortalInitialized
              ? (tabItems as TabItems[])
              : (tabItems.filter(tabItems => tabItems.label !== 'Guest Portal') as TabItems[])
            : (tabItems.filter(tabItems => tabItems.label !== 'Guest Portal') as TabItems[])
        }
        onTabChange={setTabIndex}
        onSubTabChange={selectFilter as TabBarProps['onSubTabChange']}
        tabIndex={filterIndex}
      />
      <Outlet />
    </>
  );
};

export default UnitPage;

import { ICompany, ProviderTypes } from 'company/companyType';
import { useChekinIntegration } from 'hooks/Chekin/useChekinIntegration';
import { Settings as SettingsData } from 'redux/guestportal';

/**
 * Custom hook to manage digital check-in integration.
 *
 * @returns {object} An object containing the following properties and methods:
 * - `isLoading` (boolean): Indicates if the integration is currently loading.
 * - `isSuccess` (boolean): Indicates if the integration was successful.
 * - `isEnabled` (function): Function to check if the integration is enabled.
 * - `isConnected` (boolean): Indicates if the integration is connected.
 * - `getDigitalCheckInItems` (function): Function to get digital check-in items based on settings.
 * - `getDigitalCheckInValue` (function): Function to get the digital check-in value for a company.
 * - `getDigitalCheckInPermission` (function): Function to get the digital check-in permission.
 */
export const useDigitalCheckin = () => {
  const { isLoading, isSuccess, isEnabled, isConnected } = useChekinIntegration();

  /**
   * Retrieves the digital check-in items for SettingsListItemPicker based on the provided settings.
   *
   * @param {SettingsData} settings - The settings data used to determine the digital check-in items.
   * @returns {ProviderTypes[]} An array of provider types for digital check-in.
   */
  const getDigitalCheckInItems = (settings: SettingsData) => {
    if (isLoading) return [ProviderTypes.NONE];

    if (isSuccess && isEnabled()) return [ProviderTypes.CHEKIN];

    return settings?.verificationProviders || [ProviderTypes.NONE];
  };

  /**
   * Retrieves the digital check-in value for SettingsListItemPicker for a given company.
   *
   * @param {ICompany} company - The company object to retrieve the digital check-in value for.
   * @returns {ProviderTypes} - The digital check-in provider type.
   */
  const getDigitalCheckInValue = (company: ICompany) => {
    if (isLoading) return ProviderTypes.NONE;

    if (isSuccess && isEnabled()) return ProviderTypes.CHEKIN;

    return company?.verificationProvider || ProviderTypes.NONE;
  };

  /**
   * Determines if selection is permitted for SettingsListItemPicker based on the guest portal status.
   *
   * @param {boolean} hasGuestPortal - Indicates if the guest portal is available.
   * @returns {boolean} - Returns `false` if loading or if the check-in is enabled and successful, otherwise returns the value of `hasGuestPortal`.
   */
  const getDigitalCheckInPermission = (hasGuestPortal: boolean) => {
    if (isLoading || (isSuccess && isEnabled())) return false;

    return hasGuestPortal;
  };

  return {
    isLoading,
    isSuccess,
    isEnabled,
    isConnected,
    getDigitalCheckInItems,
    getDigitalCheckInValue,
    getDigitalCheckInPermission,
  };
};

import { Stack } from '@mui/material';
import { Text } from '@operto/ui';
import ConfirmDialog, { ConfirmDialogProps } from 'Common/Dialog/ConfirmDialog';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextField } from 'ui-library/Components/input/FormTextField';

type TeamsTaskDialogProps = Pick<ConfirmDialogProps, 'loading' | 'open' | 'onCancel'> & {
  onSubmit: (task: string) => void;
};

export const TeamsTaskDialog = ({ loading, open, onCancel, onSubmit }: TeamsTaskDialogProps) => {
  const { control, reset, watch } = useForm({ mode: 'all', defaultValues: { name: '' } });

  const validate = (name: string) => {
    if (name.length === 0) {
      return 'Task name is required';
    }
    return true;
  };

  useEffect(() => {
    if (open) {
      reset({ name: '' });
    }
  }, [open, reset]);

  return (
    <ConfirmDialog
      open={open}
      title='Create Task'
      onCancel={onCancel}
      onSubmit={() => onSubmit(watch('name'))}
      loading={loading}
      disabled={validate(watch('name')) !== true}
    >
      <Stack spacing={4}>
        <Text.BodyLarge>Enter task details in the text box</Text.BodyLarge>
        <FormTextField rules={{ validate }} field='name' control={control} />
      </Stack>
    </ConfirmDialog>
  );
};

import { Divider, MenuItem } from '@mui/material';
import { companySelector, messengerSettingSelector } from 'company/state/companySelectors';
import useTranslation from 'hooks/useTranslation';
import { useAppFeatures } from 'lib/app-features';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useGuestPortal } from 'redux/guestportal';
import { useAppSelector } from 'redux/hooks';
import { IReservation } from 'reservation/reservationType';

interface ReservationActionMenuItemsProps {
  reservation?: IReservation | null;
  showEdit?: boolean;
  verifyOnClick?: () => void;
  verifyDisabled?: boolean;
  isVerificationProviderConfirmable?: boolean;
  disableManualOverride?: boolean;
  isPaymentSystemEnabled?: boolean;
  SDLabel?: string;
  disableManualSD?: boolean;
  accessCode?: string;
  reservationCodeStatus?: string;
  setOpenRegrant?: (arg: boolean) => void;
  setOpenRevoke?: (arg: boolean) => void;
  setOpenCancel?: (arg: boolean) => void;
  onReservationEditClick?: () => void;
  onSendGPLinkClick?: (reservation: IReservation, arg1: string) => void;
  onMessageClicked?: (reservation: IReservation) => void;
  onVerifyClick?: () => void;
  onManualConfirmClick?: () => void;
  onManualSecurityDepositClick?: () => void;
  onCopyClick?: (arg: string) => void;
}

const ReservationActionMenuItems = ({
  reservation,
  showEdit,
  verifyOnClick,
  verifyDisabled,
  isVerificationProviderConfirmable,
  disableManualOverride,
  isPaymentSystemEnabled,
  SDLabel,
  disableManualSD,
  accessCode,
  reservationCodeStatus,
  setOpenRegrant,
  setOpenRevoke,
  setOpenCancel,
  onReservationEditClick,
  onSendGPLinkClick,
  onMessageClicked,
  onVerifyClick,
  onManualConfirmClick,
  onManualSecurityDepositClick,
  onCopyClick,
}: ReservationActionMenuItemsProps) => {
  const { settings } = useGuestPortal();
  const guestPortalURL = process.env.REACT_APP_GUEST_PORTAL_URL;
  const messengerSetting = useAppSelector(messengerSettingSelector());
  const guestMessengerEnabled = messengerSetting.mp_guest_messenger_enabled;
  const company = useAppSelector(companySelector());
  const { isFeatureEnabled } = useAppFeatures();
  const { t } = useTranslation();

  return (
    <>
      {showEdit && (
        <MenuItem onClick={onReservationEditClick} disabled={reservation === null}>
          {t('edit_reservation')}
        </MenuItem>
      )}
      {reservationCodeStatus === 'Blocked' ? (
        <MenuItem
          onClick={() => {
            setOpenRegrant(true);
          }}
          disabled={reservation === null}
        >
          {t('regrant_reservation')}
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            setOpenRevoke(true);
          }}
          disabled={reservation === null}
        >
          {t('revoke_reservation')}
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          setOpenCancel(true);
        }}
        disabled={reservation?.source !== 'dashboard'}
      >
        {t('cancel_reservation')}
      </MenuItem>
      <Divider variant='middle' />
      {company.guestPortalStatus == 'active' && (
        <MenuItem
          onClick={() => {
            onSendGPLinkClick(
              reservation,
              `${guestPortalURL}/${company.code}_${reservation?.external_id}/?r=true`,
            );
          }}
        >
          {t('send_guest_portal_link_to_guest')}
        </MenuItem>
      )}
      {guestMessengerEnabled && (
        <MenuItem
          onClick={() => {
            onMessageClicked(reservation);
          }}
        >
          {t('send_message_to_guest')}
        </MenuItem>
      )}
      {guestMessengerEnabled && <Divider variant='middle' />}
      {verifyOnClick && settings?.guestVerification && !company.smwEnabled && (
        <MenuItem onClick={onVerifyClick} disabled={!verifyDisabled}>
          {reservation?.incident_payment_verified ? t('hide_access_code') : t('show_access_code')}
        </MenuItem>
      )}
      {isVerificationProviderConfirmable && (
        <MenuItem onClick={onManualConfirmClick} disabled={disableManualOverride}>
          {t('manually_confirm_verification')}
        </MenuItem>
      )}
      {isPaymentSystemEnabled && (
        <MenuItem onClick={onManualSecurityDepositClick} disabled={disableManualSD}>
          {SDLabel}
        </MenuItem>
      )}
      <Divider variant='middle' />
      {!company.smwEnabled && accessCode && (
        <CopyToClipboard
          onCopy={() => {
            onCopyClick(t('access_code'));
          }}
          text={accessCode}
        >
          <MenuItem>{t('copy_access_code')}</MenuItem>
        </CopyToClipboard>
      )}
      {company.smwEnabled && (
        <CopyToClipboard
          onCopy={() => {
            onCopyClick('Deeplink');
          }}
          text={reservation?.deeplink_url}
        >
          <MenuItem disabled={!reservation?.deeplink_url || reservation?.deeplink_url === ''}>
            {t('copy_deeplink')}
          </MenuItem>
        </CopyToClipboard>
      )}
      {company.guestPortalStatus == 'active' && (
        <CopyToClipboard
          onCopy={() => {
            onCopyClick(t('guest_portal_link'));
          }}
          text={`${guestPortalURL}/${company.id}_${reservation?.external_id}/?r=true`}
        >
          <MenuItem>{t('copy_guest_portal_link')}</MenuItem>
        </CopyToClipboard>
      )}
      {company.guestPortalStatus == 'active' && isFeatureEnabled('upsells', company.id) && (
        <CopyToClipboard
          onCopy={() => {
            onCopyClick(t('offers_page_link'));
          }}
          text={`${guestPortalURL}/${company.id}_${reservation?.external_id}/offers`}
        >
          <MenuItem>{t('copy_offers_page_link')}</MenuItem>
        </CopyToClipboard>
      )}
    </>
  );
};

export default ReservationActionMenuItems;

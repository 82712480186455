import { Checkbox, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Text } from '@operto/ui';
import { getTeamsServices } from 'api/messagingAPI';
import { getReservationById } from 'api/reservationAPI';
import { Loading } from 'Common/Loading';
import { logger } from 'lib/logger';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfirmDialog, ConfirmDialogProps } from '../../../Common/Dialog/ConfirmDialog';

type TeamsServicesDialogProps = Pick<ConfirmDialogProps, 'loading' | 'open' | 'onCancel'> & {
  onSubmit: (serviceId: string) => void;
};

export const TeamsServicesDialog = ({
  loading,
  open,
  onCancel,
  onSubmit,
}: TeamsServicesDialogProps) => {
  const { pathname } = useLocation();
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');
  const [fetchingServices, setFetchingServices] = useState(false);

  useEffect(() => {
    if (open) {
      setSelectedServiceId('');
    }
  }, [open]);

  const renderServices = () => {
    if (services.length === 0) {
      return <Text.BodyLarge>No tasks found.</Text.BodyLarge>;
    }

    return (
      <List dense sx={{ maxHeight: 500 }}>
        {services.map(service => {
          return (
            <ListItem key={service.id} disablePadding>
              <ListItemButton onClick={() => setSelectedServiceId(service.id)}>
                <ListItemText primary={service.name} />
                <Checkbox
                  edge='end'
                  onChange={() => setSelectedServiceId(service.id)}
                  checked={selectedServiceId === service.id}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        setFetchingServices(true);
        const reservationId = pathname.split('-').pop();
        if (!reservationId) {
          throw new Error('Reservation ID not found');
        }

        const reservation = await getReservationById(reservationId);
        const reservationExternalId = reservation?.data?.data?.external_id;
        if (!reservationExternalId) {
          throw new Error('Reservation external ID not found');
        }

        const response = await getTeamsServices(reservationExternalId);
        const data = response.data?.data;
        if ('error' in data) {
          throw new Error(data.error);
        }

        setServices(data);
      } catch (error) {
        logger.error('Fetching services error: ', error);
      } finally {
        setFetchingServices(false);
      }
    };

    fetchServices();
  }, [pathname]);

  return (
    <ConfirmDialog
      open={open}
      title='Create Task'
      onCancel={onCancel}
      onSubmit={() => onSubmit(selectedServiceId)}
      loading={loading}
      disabled={!selectedServiceId}
    >
      {fetchingServices ? <Loading /> : renderServices()}
    </ConfirmDialog>
  );
};

export interface IntegrationPayload {
  status: string;
  message: string;
  data: IntegrationDataPayload[];
}

export interface IntegrationDataPayload {
  id: number;
  integration_provider_name: string;
  label: string;
  enabled: boolean;
  api_system: string;
  integration_provider_id: number | null;
}

export type TIntegrationPayload =
  | IntegrationPayload
  | TGuestyPayload
  | TMewsPayload
  | THostawayPayload
  | TStreamlinePayload;

export interface IIntegration {
  id: number;
  name: string;
  label: string;
  apiSystem: string;
  providerId: number | null;
  enabled: boolean;
  provider?: Record<string, unknown>;
  fallback?: boolean;
}
export interface IntegrationDict {
  [id: string]: IIntegration;
}

export interface IntegrationState {
  list: string[];
  byId: IntegrationDict;
}

export type TGuestyPayload = {
  provider: string;
  api_token: string;
};

export type TMewsPayload = {
  provider: string;
  api_token: string;
};

export type THostawayPayload = {
  provider: string;
  client_id: string;
  api_secret: string;
};

export type TStreamlinePayload = {
  provider: string;
  api_key: string;
  api_secret: string;
};

export enum IntegrationProvider {
  Cloudbeds = 'CLOUDBEDS',
  Guesty = 'GUESTY',
  HostAway = 'HOSTAWAY',
  VRScheduler = 'vrs-scheduler',
  MyVr = 'my-vr',
  Mews = 'MEWS',
  Avantio = 'avantio',
  Kigo = 'kigo',
  WebRezPro = 'webrezpro',
  Hostfully = 'hostfully',
  Lightmaker = 'lightmaker',
  Direct = 'direct',
  Tado = 'tado',
  Streamline = 'STREAMLINE',
}

export const IntegrationApiProvider = {
  Cloudbeds: 'cloudbeds-jcore',
  Guesty: 'guesty-jcore',
  Mews: 'mews-jcore',
  Hostaway: 'hostaway-jcore',
  Streamline: 'streamline-jcore',
} as const;

// Convert IntegrationApiProvider key in a type
export type IntegrationApiProviderType =
  (typeof IntegrationApiProvider)[keyof typeof IntegrationApiProvider];

export enum IntegrationsApiSystem {
  PAYMENT = 'payment',
}
